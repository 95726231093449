import { Suspense } from "react";
import dynamic from "next/dynamic";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import LayoutAccount from "@/layouts/Account";

import type { NextPageWithLayout } from "@/pages/_app";
import type { GetStaticProps } from "next";

const ViewLogin = dynamic(() => import("@/views/Login"), {
  suspense: true,
  ssr: false,
});

const Login: NextPageWithLayout = () => {
  return (
    <Suspense fallback="...">
      <ViewLogin />
    </Suspense>
  );
};

Login.getLayout = (page) => {
  return <LayoutAccount>{page}</LayoutAccount>;
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "")),
    },
  };
};

export default Login;
