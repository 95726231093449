import companySettingApi, {
  IGetAllSettingResponse,
} from "@/utils/api/companySetting";
import { gotoPage } from "@/utils/helpers/common";
import React, { useEffect, useState } from "react";

type MainProps = {
  children: React.ReactNode;
};

const Account = (props: MainProps) => {
  const { children } = props;
  const [isMobile, setIfMobile] = useState<boolean>(false);
  const [companySetting, setCompanySetting] =
    useState<IGetAllSettingResponse | null>(null);

  let getCompanySetting = async () => {
    try {
      let res = await companySettingApi.getAllSetting({
        params: {
          language: "eng",
        },
      });
      setCompanySetting(res.data.params);
    } catch (error) {
      console.log(error);
    }
  };

  let listenResizeWindows = () => {
    setIfMobile(window.innerWidth < 1280);
  };

  useEffect(() => {
    window.addEventListener("resize", listenResizeWindows);
    listenResizeWindows();
    getCompanySetting();
  }, []);

  return (
    <div className="flex flex-col w-screen h-full">
      {isMobile ? (
        <div className="w-screen h-[124px] bg-main-cover flex items-center justify-center">
          <div className="w-screen h-[124px] bg-main-cover-faded z-10 absolute top-0 left-0"></div>
          <img
            className="w-[auto] h-[62px] z-20"
            src={companySetting?.content_logo}
            alt=""
          />
        </div>
      ) : (
        <div className="fixed top-0 right-0 w-[40%] h-screen">
          <img
            className="fixed top-0 right-0 object-cover object-[25%_0] w-[40%] h-screen z-10"
            src={companySetting?.content_registration_image}
            alt=""
          />
          <img
            className="fixed top-0 right-0 w-[40%] h-screen z-20"
            src="/img/faded-right.png"
            alt=""
          />
          <img
            className="fixed  z-30 top-20 right-10 cursor-pointer"
            src={companySetting?.content_logo}
            alt=""
            onClick={() => gotoPage("/")}
          />
        </div>
      )}
      <div className="flex flex-col justify-center pc:items-center w-full pc:w-[60%] h-full">
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Account;
